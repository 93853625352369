import { Typography } from '@/components/Typography';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import { aboutUsPage } from '@/utils/contentData/aboutUsPage';
import './AkuityStory.scss';

const { title, description, image } = aboutUsPage.akuityStory;

const AkuityStory = () => {
  const { isBreakpointRange } = useBreakpoints();

  return (
    <div className="akuity-story-container container-fluid content--max-width">
      <div className="akuity-hero-description row no-gap m-0">
        <div className="akuity-hero-description__text col-md-12 col-xl-4">
          <>
            {!isBreakpointRange('desktopSmall') ? (
              <Typography
                component="h1"
                typographyKind="header-primary-large"
                size={80}
                lineHeight={92}
                color="secondary-one-hundred"
              >
                {title}
              </Typography>
            ) : (
              <Typography
                component="h1"
                typographyKind="header-primary-medium-bold"
                color="secondary-one-hundred"
                size={48}
                lineHeight={60}
              >
                {title}
              </Typography>
            )}
          </>
        </div>
        <div className="akuity-hero-description__img col-md-12 col-xl-8 no-gap">
          <img alt={image.alt} src={image.desktop} />
        </div>
      </div>
      <div className="akuity-story__description">
        <Typography
          component="p"
          typographyKind="text-primary-extra-large-medium"
          size={24}
          lineHeight={40}
        >
          {description}
        </Typography>
      </div>
    </div>
  );
};

export default AkuityStory;
