import { useBreakpoints } from '@/hooks/useBreakpoints';

export const viewPercentageConfig = () => {
  const { isBreakpointRange, width } = useBreakpoints();
  if (width > 2456 && width <= 10000) return 26;
  if (isBreakpointRange('desktopFull', 'widthFull')) return 31;
  if (isBreakpointRange('desktopBig', 'desktopFull')) return 38;
  if (width > 1400 && width <= 1580) return 37;
  if (width > 1200 && width <= 1400) return 42;
  if (isBreakpointRange('desktop', 'desktopMedium')) return 45;
  if (isBreakpointRange('desktopSmall', 'desktop')) return 40;
  if (isBreakpointRange('tabletBig', 'desktopSmall')) return 50;
  return 100;
};
