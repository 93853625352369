import Image from '@/components/Image';
import { Typography } from '@/components/Typography';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import { aboutUsPage } from '@/utils/contentData/aboutUsPage';
import './Founders.scss';

type FounderProfileType = {
  imageSrc: string;
  title: string;
  description: string;
  imageAlt?: string;
};

const { title, items: foundersList } = aboutUsPage.founders;

const FounderProfile = ({
  imageSrc,
  title,
  description,
  imageAlt = ''
}: FounderProfileType) => {
  const { isBreakpointRange } = useBreakpoints();

  const isBelowDesktop = isBreakpointRange('desktop');

  return (
    <div className="founder-profile-container container-fluid">
      <div className="founder-profile-wrapper row">
        <div className="founder-profile__image col-md-12 col-xl-2">
          <Image imageUrl={imageSrc} alt={imageAlt} />
        </div>
        <div className="founder-profile-text col-md-12 col-xl-10">
          <div className="founder-profile-text__title">
            {isBelowDesktop ? (
              <Typography
                component="p"
                typographyKind="text-primary-large-bold"
                size={20}
                lineHeight={24}
              >
                {title}
              </Typography>
            ) : (
              <Typography
                component="h5"
                typographyKind="header-secondary-small-bold"
                size={24}
                lineHeight={32}
              >
                {title}
              </Typography>
            )}
          </div>
          <div className="founder-profile-text__description">
            <Typography
              component="p"
              typographyKind="text-secondary-large-medium"
              size={16}
              lineHeight={24}
            >
              {description}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const Founders = () => {
  const { isBreakpointRange } = useBreakpoints();

  const isBelowDesktop = isBreakpointRange('desktop');
  return (
    <div className="akuity-story-founders-container content--max-width">
      <div className="akuity-story-founders-heading">
        <Typography
          component="h2"
          typographyKind={
            isBelowDesktop
              ? 'header-secondary-small-bold'
              : 'header-secondary-large-bold'
          }
          size={isBelowDesktop ? 24 : 32}
          lineHeight={isBelowDesktop ? 32 : 44}
        >
          {title}
        </Typography>
      </div>
      {(foundersList || []).map(({ id, title, description, image }) => (
        <FounderProfile
          key={id}
          imageSrc={image.url}
          title={title}
          description={description}
          imageAlt={image.alt}
        />
      ))}
    </div>
  );
};

export default Founders;
