import BackgroundImage from '@/components/BackgroundImage';
import LazyImage from '@/components/LazyImage';
import { Typography } from '@/components/Typography';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import { aboutUsPage } from '@/utils/contentData/aboutUsPage';
import './OurCommitment.scss';

type CommitmentIllustration = {
  heading: string;
  description: string;
  image: { name: string; alt: string };
  isMobile: boolean;
};

const { akuity, argo, title, image } = aboutUsPage.ourCommitment;

const CommitmentIllustration = ({
  heading,
  description,
  image,
  isMobile
}: CommitmentIllustration) => {
  return (
    <div className="about-commitment__illustration">
      <div className="about-commitment__illustration--heading">
        <Typography
          component="h2"
          typographyKind="header-secondary-small-bold"
          size={24}
          lineHeight={32}
        >
          {heading}
        </Typography>
      </div>
      <div className="about-commitment__illustration--description">
        <Typography
          component="p"
          typographyKind="text-secondary-large-medium"
          size={isMobile ? 16 : 14}
          lineHeight={isMobile ? 24 : 20}
        >
          {description}
        </Typography>
      </div>
      <LazyImage imageName={image.name} alt={image.alt} />
    </div>
  );
};

const OurCommitment = () => {
  const { isBreakpointRange } = useBreakpoints();
  const isDesktopSmall = !isBreakpointRange('desktop');

  return (
    <div className="about-commitment-container position-relative ">
      <div className="about-commitment-heading content--max-width">
        <Typography
          component="h2"
          typographyKind={
            isDesktopSmall
              ? 'header-primary-medium-bold'
              : 'header-secondary-large-bold'
          }
          size={isDesktopSmall ? 48 : 32}
          lineHeight={isDesktopSmall ? 60 : 44}
        >
          {title}
        </Typography>
      </div>
      <BackgroundImage
        imageUrl={image.url}
        imageMobileUrl={image.urlMobile}
        imageDesktopFull={image.urlDesktopFullHD}
        additionalClassContainer={'about-commitment-image'}
        alt={image.alt}
      >
        <div className="container about-commitment-wrapper content--max-width">
          <div className="about-commitment-illustration__wrapper no-padding-global">
            <CommitmentIllustration
              heading={akuity.title}
              description={akuity.description}
              image={akuity.image}
              isMobile={isDesktopSmall}
            />
            <CommitmentIllustration
              heading={argo.title}
              description={argo.description}
              image={argo.image}
              isMobile={isDesktopSmall}
            />
          </div>
        </div>
      </BackgroundImage>
    </div>
  );
};

export default OurCommitment;
