import { useState, useEffect } from 'react';
import { useBreakpoints } from './useBreakpoints';

export type CarouselHookType = {
  initializer: JSX.Element[];
  viewPercentageConfig?: () => number;
};

const useCarousel = ({
  initializer,
  viewPercentageConfig
}: CarouselHookType) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideList, setSlideList] = useState<JSX.Element[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfItemsOnLastPage, setNumberOfItemsOnLastPage] = useState(0);

  const { isBreakpointRange } = useBreakpoints();

  const viewPercentageConfigDefault = () => {
    if (isBreakpointRange('desktopFull', 'widthFull')) return 26;
    if (isBreakpointRange('desktopMedium', 'widthFull')) return 30;
    if (isBreakpointRange('desktopSmall', 'desktopMedium')) return 40;
    if (isBreakpointRange('tablet', 'desktopSmall')) return 50;
    return 100;
  };

  const getViewPercentageConfig = () =>
    viewPercentageConfig
      ? viewPercentageConfig()
      : viewPercentageConfigDefault();

  const numberOfItemsOnPage = Math.floor(100 / getViewPercentageConfig());

  const numberOfPages = Math.ceil(slideList.length / numberOfItemsOnPage);
  const getNumberOfItemsOnLastPage = () => {
    const restOfDivision = slideList.length % numberOfItemsOnPage;

    return restOfDivision === 0 ? numberOfItemsOnPage : restOfDivision;
  };

  const setNextSlide = () => {
    setSlide(currentSlide + numberOfItemsOnPage);
  };

  const setPreviousSlide = () => {
    setSlide(currentSlide - numberOfItemsOnPage);
  };

  const setSlide = (nextSlide: number) => {
    if (nextSlide > slideList.length - 1) {
      setCurrentPage(1);
      setCurrentSlide(0);
      return;
    }

    if (nextSlide < 0) {
      setCurrentPage(numberOfPages);
      setCurrentSlide(slideList.length - numberOfItemsOnLastPage);
      return;
    }
    setCurrentSlide(nextSlide);
    setCurrentPage(nextSlide / numberOfItemsOnPage + 1);
  };

  useEffect(() => {
    setSlideList(initializer);
  }, []);

  useEffect(() => {
    setNumberOfItemsOnLastPage(getNumberOfItemsOnLastPage());
  }, [numberOfItemsOnPage]);

  return {
    slide: currentSlide,
    slides: slideList,
    viewPercentage: getViewPercentageConfig(),
    page: currentPage,
    numberOfItemsOnPage,
    numberOfPages,
    numberOfItemsOnLastPage,
    setSlide: (val: number) => setSlide(val),
    setSlides: (val: JSX.Element[]) => setSlideList(val),
    setPage: (val: number) => setCurrentPage(val),
    setNextSlide,
    setPreviousSlide
  };
};

export { useCarousel };
