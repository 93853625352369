import { bannerProfiles, decibelBanner, leadEdgeBanner } from './utils';
import Profile from '@/components/Profile';
import { Typography } from '@/components/Typography';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import {
  DecibelTextDesktopIcon,
  DecibelTextMobileIcon,
  LeadEdgeTextDesktopIcon,
  LeadEdgeTextMobileIcon
} from '@/static/assets/icons/AboutUs';
import './DecibelBanner.scss';

interface DecibelBannerType {
  variant: 'decibel' | 'leadedge';
}

const DecibelBannerDesktop = ({ variant }: DecibelBannerType) => {
  const selectedProfile =
    variant === 'decibel' ? bannerProfiles.decibel : bannerProfiles.leadEdge;

  return (
    <div
      className={`desktop-investors__additional-banner row ${
        variant === 'leadedge' ? 'banner__padded' : ''
      }`}
    >
      <div className="desktop-investors__banner--profile col-4">
        <Profile
          key={selectedProfile.alt}
          avatar={selectedProfile.avatar}
          heading={selectedProfile.heading}
          redirectLink={selectedProfile.redirectLink}
          description={selectedProfile.description}
          profileIcon={selectedProfile.profileIcon}
          alt={selectedProfile.alt}
        />
      </div>
      <div className="desktop-investors__description col-8">
        <div className="desktop-investors__additional-banner--heading">
          {variant === 'decibel' ? (
            <DecibelTextDesktopIcon />
          ) : (
            <LeadEdgeTextDesktopIcon />
          )}
        </div>
        <div className="desktop-investors__additional-banner--description">
          <Typography
            component="p"
            typographyKind="text-secondary-large-medium"
            size={16}
            lineHeight={24}
          >
            {selectedProfile.text}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const DecibelBannerMobile = ({ variant }: DecibelBannerType) => {
  return (
    <div
      className={`mobile-investors__additional-banner ${
        variant === 'decibel' ? '' : 'padded'
      }`}
    >
      <div className="mobile-investors__additional-banner--heading">
        {variant === 'decibel' ? (
          <DecibelTextMobileIcon />
        ) : (
          <LeadEdgeTextMobileIcon />
        )}
      </div>
      <div className="mobile-investors__additional-banner--description">
        <Typography
          component="p"
          typographyKind="text-secondary-small"
          size={12}
          lineHeight={16}
        >
          {variant === 'decibel' ? decibelBanner.text : leadEdgeBanner.text}
        </Typography>
      </div>
    </div>
  );
};

const DecibelBanner = () => {
  const { isBreakpointRange } = useBreakpoints();
  const isAboveDesktopSmall = !isBreakpointRange('desktop');

  return (
    <>
      {isAboveDesktopSmall ? (
        <>
          <DecibelBannerDesktop variant="decibel" />
          <DecibelBannerDesktop variant="leadedge" />
        </>
      ) : (
        <>
          <DecibelBannerMobile variant="decibel" />
          <DecibelBannerMobile variant="leadedge" />
        </>
      )}
    </>
  );
};

export default DecibelBanner;
