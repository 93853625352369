import { Button } from '@/components/Button';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { trackEvent } from '@/helpers/analytics';
import AkuityStory from '@/sections/About/components/AkuityStory';
import Founders from '@/sections/About/components/Founders';
import Investors from '@/sections/About/components/Investors';
import OurCommitment from '@/sections/About/components/OurCommitment';
import OurMilestones from '@/sections/About/components/OurMilestones';
import OurMission from '@/sections/About/components/OurMission';
import { seoConfiguration } from '@/utils/seo-config';
import './company.scss';

const AboutPage = () => {
  const { title: titleSeo, image, description } = seoConfiguration.about;

  return (
    <Layout image={image} title={titleSeo} description={description}>
      <AkuityStory />
      <OurMission />
      <Founders />
      <OurCommitment />
      <Investors />
      <OurMilestones />
      <div className="no-global-padding cta-wrapper">
        <div className="padding-global">
          <PageHeader
            textPrimary={{
              text: content.cta.header,
              typographyKind: 'header-primary-medium-bold',
              color: 'default',
              size: 40
            }}
            textSecondary={{
              text: content.cta.header2,
              typographyKind: 'header-primary-medium-bold',
              size: 38,
              color: 'default'
            }}
            textDescription={{
              text: content.cta.subheader,
              color: 'default',
              className: 'subheader'
            }}
          />

          <div className="cta-buttons col-6">
            <Button
              additionalClass="trial button-hover-color--primary-subtle"
              link={content.cta.trial.url}
              isExternalLink={content.cta.trial.isExternalLink}
              onClickFunc={() => trackEvent('akuity_io_to_cloud')}
            >
              {content.cta.trial.text}
            </Button>
            <Button
              additionalClass="demo button-hover-color--secondary"
              link={content.cta.demo.url}
              isExternalLink={content.cta.demo.isExternalLink}
            >
              {content.cta.demo.text}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const content = {
  cta: {
    header: `Secure and Scalable Kubernetes Application Delivery.`,
    header2: `Akuity enables DevOps to do more, with less effort`,
    subheader: 'Ready to get started with Akuity?',
    trial: {
      text: 'Start a Free Trial',
      url: '/signup',
      isExternalLink: true
    },
    demo: {
      text: 'Get a Technical Demo',
      url: '/get-demo',
      isExternalLink: false
    }
  }
};

export default AboutPage;
