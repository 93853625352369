import { useRef } from 'react';
import DecibelBanner from '../DecibelBanner';
import { Carousel } from '@/components/Carousel';
import {
  CarouselArrowLeftIcon,
  CarouselArrowRightIcon
} from '@/static/assets/icons';
import { viewPercentageConfig } from '@/utils/carousel';
import './InvestorsMobile.scss';

type InvestorsMobileType = {
  profiles: JSX.Element[];
};

const InvestorsMobile = ({ profiles }: InvestorsMobileType) => {
  const nextSlide = useRef(() => {});
  const previousSlide = useRef(() => {});

  return (
    <>
      <div className={'col-sm-12 col-md-1 investors-mobile__arrows-col'}>
        <div onClick={() => previousSlide.current()}>
          <CarouselArrowLeftIcon />
        </div>
        <div onClick={() => nextSlide.current()}>
          <CarouselArrowRightIcon />
        </div>
      </div>
      <div className={'take-word__carousel-wrapper'}>
        <Carousel
          listOfSlides={profiles}
          viewPercentageConfig={viewPercentageConfig}
          nextSlide={nextSlide}
          previousSlide={previousSlide}
          showThumbs={false}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          centerMode
          useKeyboardArrows
        />
      </div>
      <DecibelBanner />
    </>
  );
};

export default InvestorsMobile;
