import { CSSProperties, useEffect, useRef } from 'react';
import { Carousel as CarouselLib } from 'react-responsive-carousel';
import { CarouselType } from '@/components/Carousel/types';
import { mapObjectToStringWithKebabCaseKey } from '@/helpers';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import { useCarousel } from '@/hooks/useCarousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const Carousel = ({
  listOfSlides = [],
  nextSlide = { current: () => {} },
  previousSlide = { current: () => {} },
  viewPercentageConfig,
  centerSlides = true,
  ...props
}: CarouselType) => {
  const {
    slide,
    setSlide,
    setNextSlide,
    setPreviousSlide,
    viewPercentage,
    numberOfItemsOnPage,
    numberOfPages,
    page,
    setPage
  } = useCarousel({ initializer: listOfSlides, viewPercentageConfig });

  const { isBreakpointRange } = useBreakpoints();

  const isRenderDots = isBreakpointRange('desktopSmall');

  const isSwipeable = isBreakpointRange('tabletBig');

  const sliderStyleDefault: CSSProperties & { [key: string]: string } = {
    transitionDuration: '350ms',
    transform: 'translate3d(0%, 0px, 0px)'
  };

  const getCLassSelected = (index: number) =>
    index === page - 1 ? 'selected' : '';

  const getTranslatedFirstValue = () => {
    return slide === 0 ? 0 : slide * viewPercentage;
  };

  const setTranslatedFirstValue = () => {
    sliderStyleDefault.transform = `translate3d(-${getTranslatedFirstValue()}%, 0px, 0px)`;
  };

  const setSliderStyle = () => {
    setTranslatedFirstValue();
    const slider = carousel.current?.listRef as HTMLElement;
    const newSliderStyle =
      mapObjectToStringWithKebabCaseKey(sliderStyleDefault);

    if (!slider) return;

    slider.setAttribute('style', newSliderStyle);
  };

  const setCarouselPage = (val: number) => {
    if (numberOfItemsOnPage === 1) {
      setSlide(val);
      setPage(val + 1);
    }
  };

  const setCarouselSlide = (index: number) => {
    setSlide(index * numberOfItemsOnPage);
  };

  const carousel = useRef<any>(CarouselLib);

  useEffect(() => {
    nextSlide.current = setNextSlide;
    previousSlide.current = setPreviousSlide;
  });

  useEffect(() => {
    setTimeout(() => {
      setSliderStyle();
    }, 50);
  }, [slide]);

  return (
    <>
      <CarouselLib
        ref={carousel}
        {...props}
        onChange={(val) => setCarouselPage(val)}
        swipeable={isSwipeable}
        selectedItem={slide}
        centerSlidePercentage={viewPercentage}
      >
        {listOfSlides}
      </CarouselLib>
      {isRenderDots && (
        <div className="carousel-dot-container">
          {[...Array(numberOfPages).keys()].map((_, index) => (
            <div
              key={`dot__${index}`}
              className={`carousel__dot ${getCLassSelected(index)}`}
              onClick={() => {
                setCarouselSlide(index);
              }}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default Carousel;
