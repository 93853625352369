import { memo } from 'react';
import {
  PageHeaderType,
  TextConfigRequiredType
} from '@/components/PageHeader/types';
import { Typography } from '@/components/Typography';
import './PageHeader.scss';

const PageHeader = ({
  textPrimary,
  textSecondary = { text: '' },
  textDescription = { text: '' },
  additionalClassContainer = '',
  image, // when image is undefined then component have just one column and column with text is center
  classTextColumn = image
    ? 'col-sm-12 col-md-8 col-xl-7 page-header__text-colum--padding'
    : 'col text-align-center',
  classImageColumn = 'col-sm-12 col-md-4 col-xl-5',
  classRow = image ? 'row' : 'row justify-content-center',
  additionalClassImageColumn = '',
  additionalClassTextColumn = '',
  classContainer = 'container-fluid'
}: PageHeaderType) => {
  const classTextColumnAll = `${classTextColumn} ${additionalClassTextColumn}`;
  const classImageColumnAll = `${classImageColumn} ${additionalClassImageColumn} no-gap`;

  // padding and margin: you can use number from 1 to 64 first number for vertical second horizontal, it's possible to add one value f.e [1]
  const textPrimaryConfig: TextConfigRequiredType = {
    ...{
      color: 'primary',
      typographyKind: 'header-primary-large',
      padding: [0],
      margin: [6, 0],
      className: '',
      size: 0,
      lineHeight: 0
    },
    ...textPrimary
  };
  const textSecondaryConfig: TextConfigRequiredType = {
    ...{
      color: 'primary',
      typographyKind: 'header-secondary-medium',
      padding: [0],
      margin: [0],
      className: '',
      size: 0,
      lineHeight: 0
    },
    ...textSecondary
  };
  const textDescriptionConfig: TextConfigRequiredType = {
    ...{
      color: 'primary',
      typographyKind: 'text-secondary-extra-large',
      padding: [0],
      margin: [8, 0],
      size: 0,
      className: '',
      lineHeight: 0
    },
    ...textDescription
  };

  const ImageColumn = () => {
    return (
      <div
        className={`image-wrapper ${classImageColumnAll} position-relative margin-top-auto margin-bottom-auto`}
      >
        {image}
      </div>
    );
  };

  const TextDescription = () => {
    if (typeof textDescriptionConfig.text === 'string') {
      return (
        <Typography
          className={textDescriptionConfig.className}
          component={'p'}
          color={textDescriptionConfig.color}
          typographyKind={textDescriptionConfig.typographyKind}
          margin={textDescriptionConfig.margin}
          size={textDescriptionConfig.size}
          lineHeight={textDescriptionConfig.lineHeight}
        >
          {textDescriptionConfig.text}
        </Typography>
      );
    }

    return <>{textDescriptionConfig.text}</>;
  };

  return (
    <div
      className={`page-header ${additionalClassContainer} content--max-width`}
    >
      <div className={classContainer}>
        <div className={classRow}>
          <div className={classTextColumnAll}>
            <div>
              <Typography
                className={textPrimaryConfig.className}
                component={'h1'}
                color={textPrimaryConfig.color}
                typographyKind={textPrimaryConfig.typographyKind}
                margin={textPrimaryConfig.margin}
                size={textPrimaryConfig.size}
                lineHeight={textPrimaryConfig.lineHeight}
              >
                {textPrimaryConfig.text}
              </Typography>
              <Typography
                className={textSecondaryConfig.className}
                component={'h2'}
                color={textSecondaryConfig.color}
                typographyKind={textSecondaryConfig.typographyKind}
                margin={textSecondaryConfig.margin}
                size={textSecondaryConfig.size}
                lineHeight={textSecondaryConfig.lineHeight}
              >
                {textSecondaryConfig.text}
              </Typography>
              <TextDescription />
            </div>
          </div>
          {image ? <ImageColumn /> : ''}
        </div>
      </div>
    </div>
  );
};
export default memo(PageHeader);
