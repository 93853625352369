import { ProfileType } from '@/components/Profile/types';
import { en, PageDataType } from '@/languages/en';
import JonSakoda from '@/static/assets/images/Common/Persons/jonsakoda.jpeg';
import NimayMehta from '@/static/assets/images/Common/Persons/nimaymehta.png';
export const {
  aboutUs: {
    investorsAndAdvisors: { decibelBanner, leadEdgeBanner }
  }
}: PageDataType = en;

interface BannerProfiles {
  decibel: ProfileType & { text: string };
  leadEdge: ProfileType & { text: string };
}

export const bannerProfiles: BannerProfiles = {
  decibel: {
    avatar: JonSakoda,
    heading: decibelBanner.profile.name,
    description: decibelBanner.profile.description,
    redirectLink: decibelBanner.profile.redirectLink,
    profileIcon: 'linkedin',
    alt: 'st-aug',
    text: decibelBanner.text
  },
  leadEdge: {
    avatar: NimayMehta,
    heading: leadEdgeBanner.profile.name,
    description: leadEdgeBanner.profile.description,
    redirectLink: leadEdgeBanner.profile.redirectLink,
    profileIcon: 'linkedin',
    alt: 'sy-banon',
    text: leadEdgeBanner.text
  }
};
