import { Typography } from '../Typography';
import './Milestones.scss';
import { MilestonesType } from './types';
import { useBreakpoints } from '@/hooks/useBreakpoints';

const Milestones = ({ milestoneList }: MilestonesType) => {
  const isLastOddItem = (idx: number) =>
    milestoneList.length === idx + 1 && idx % 2 !== 0;
  const isLastEvenItem = (idx: number) =>
    milestoneList.length === idx + 1 && idx % 2 === 0;
  const isOddItem = (idx: number) => idx % 2 !== 0;

  const getMilestones = () => {
    const { isBreakpointRange } = useBreakpoints();
    const isAboveDesktopSmall = !isBreakpointRange('desktop');

    const milestoneLineText = (
      title: string,
      lineType: 'lower-line-event' | 'upper-line-event'
    ) =>
      isAboveDesktopSmall ? (
        <Typography
          component={'h5'}
          typographyKind={'header-secondary-small-bold'}
          className={lineType}
          size={24}
          lineHeight={32}
        >
          {title}
        </Typography>
      ) : (
        <Typography
          component={'p'}
          typographyKind={'text-primary-medium-bold'}
          className={lineType}
          size={16}
          lineHeight={24}
        >
          {title}
        </Typography>
      );

    return milestoneList
      .concat(milestoneList)
      .map(({ title, month, year }, idx) => {
        if (isLastOddItem(idx)) {
          return (
            <div key={`${title}-${idx}`} className="wrapper last">
              <div className="upper-line-wrapper">
                <div className="dot" />
                <div className="vertical-line" />
              </div>
              {milestoneLineText(title, 'upper-line-event')}
              <div className="box-wrapper">
                <p className="month">{month}</p>
                <p className="year">{year}</p>
              </div>
            </div>
          );
        } else if (isLastEvenItem(idx)) {
          return (
            <div key={`${title}-${idx}`} className="wrapper last">
              <div className="lower-line-wrapper">
                <div className="dot" />
                <div className="vertical-line" />
              </div>
              {milestoneLineText(title, 'lower-line-event')}
              <div className="box-wrapper">
                <p className="month">{month}</p>
                <p className="year">{year}</p>
              </div>
            </div>
          );
        } else if (isOddItem(idx)) {
          return (
            <div key={`${title}-${idx}`} className="wrapper">
              <div className="upper-line-wrapper">
                <div className="dot" />
                <div className="vertical-line" />
              </div>
              {milestoneLineText(title, 'upper-line-event')}
              <div className="horizontal-line"></div>
              <div className="box-wrapper">
                <p className="month">{month}</p>
                <p className="year">{year}</p>
              </div>
            </div>
          );
        }

        return (
          <div key={`${title}-${idx}`} className="wrapper">
            <div className="lower-line-wrapper">
              <div className="dot" />
              <div className="vertical-line" />
            </div>
            {milestoneLineText(title, 'lower-line-event')}
            <div className="horizontal-line" />
            <div className="box-wrapper">
              <p className="month">{month}</p>
              <p className="year">{year}</p>
            </div>
          </div>
        );
      });
  };

  return (
    <div className="milestones no-global-padding">
      <div className="milestones-carousel">
        <div className="milestones-carousel-container">{getMilestones()}</div>
      </div>
    </div>
  );
};

export default Milestones;
