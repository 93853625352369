import Milestones from '@/components/Milestones';
import { Typography } from '@/components/Typography';
import './OurMilestones.scss';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import { aboutUsPage } from '@/utils/contentData/aboutUsPage';

const { title, items: milestones } = aboutUsPage.ourMilestones;

const editableMilestones = [...milestones];

const OurMilestones = () => {
  const { isBreakpointRange } = useBreakpoints();
  const isDesktopSmall = !isBreakpointRange('desktop');

  return (
    <div className="our-milestones-container content--max-width">
      <div className="our-milestones__heading">
        <Typography
          component="h2"
          typographyKind={
            isDesktopSmall
              ? 'header-primary-medium-bold'
              : 'header-secondary-large-bold'
          }
          size={isDesktopSmall ? 48 : 32}
          lineHeight={isDesktopSmall ? 60 : 44}
        >
          {title}
        </Typography>
      </div>
      <Milestones milestoneList={editableMilestones} />
    </div>
  );
};

export default OurMilestones;
