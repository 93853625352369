import { Typography } from '@/components/Typography';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import './OurMission.scss';
import { aboutUsPage } from '@/utils/contentData/aboutUsPage';

const { title, description } = aboutUsPage.ourMission;

const OurMission = () => {
  const { isBreakpointRange } = useBreakpoints();

  return (
    <div className="about-us-mission-container w-100 flex-column justify-center content--max-width">
      <div className="about-us-mission-heading">
        {!isBreakpointRange('desktop') ? (
          <Typography
            component="h2"
            typographyKind="header-primary-medium-bold"
            size={48}
            lineHeight={60}
          >
            {title}
          </Typography>
        ) : (
          <Typography
            component="h2"
            typographyKind="header-secondary-large-bold"
            size={32}
            lineHeight={44}
          >
            {title}
          </Typography>
        )}
      </div>
      <div className="about-us-mission-description">
        {!isBreakpointRange('desktop') ? (
          <Typography
            component="p"
            typographyKind="text-secondary-medium"
            size={28}
            lineHeight={40}
          >
            {description}
          </Typography>
        ) : (
          <Typography
            component="h5"
            typographyKind="header-secondary-medium-small"
            size={24}
            lineHeight={40}
          >
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default OurMission;
