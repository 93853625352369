import DecibelBanner from '../DecibelBanner';

type InvestorsDesktopType = {
  profiles: JSX.Element[];
};

const InvestorsDesktop = ({ profiles }: InvestorsDesktopType) => {
  return (
    <div className="container desktop-investors__content-wrapper">
      <div className="row">
        {profiles}
        <DecibelBanner />
      </div>
    </div>
  );
};

export default InvestorsDesktop;
