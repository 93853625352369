import InvestorsDesktop from './components/InvestorsDesktop';
import InvestorsMobile from './components/InvestorsMobile';
import Profile from '@/components/Profile';
import { Typography } from '@/components/Typography';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import { aboutUsPage } from '@/utils/contentData/aboutUsPage';
import './Investors.scss';

const { items: profiles, title, description } = aboutUsPage.investors;

const getListOfProfiles = () =>
  profiles.map((profile) => (
    <div className="col-xl-4" key={profile.alt}>
      <Profile
        key={profile.alt}
        avatar={profile.avatar}
        heading={profile.heading}
        redirectLink={profile.redirectLink}
        description={profile.description}
        profileIcon={profile.profileIcon as any}
        alt={profile.alt}
      />
    </div>
  ));

const Investors = () => {
  const { isBreakpointRange } = useBreakpoints();
  const isAboveDesktopSmall = !isBreakpointRange('desktop');

  return (
    <div className="investors-advisors-container content--max-width">
      <div className="investors-advisors__heading">
        <Typography
          component="h2"
          typographyKind={
            isAboveDesktopSmall
              ? 'header-primary-medium-bold'
              : 'header-secondary-large-bold'
          }
          size={isAboveDesktopSmall ? 48 : 32}
          lineHeight={isAboveDesktopSmall ? 60 : 44}
        >
          {title}
        </Typography>
      </div>
      <div className="investors-advisors__sub-heading">
        <Typography
          component="p"
          typographyKind={
            isAboveDesktopSmall
              ? 'text-secondary-extra-large'
              : 'text-secondary-medium'
          }
          size={isAboveDesktopSmall ? 20 : 14}
          lineHeight={isAboveDesktopSmall ? 32 : 20}
        >
          {description}
        </Typography>
      </div>
      {isAboveDesktopSmall ? (
        <InvestorsDesktop profiles={getListOfProfiles()} />
      ) : (
        <InvestorsMobile profiles={getListOfProfiles()} />
      )}
    </div>
  );
};

export default Investors;
