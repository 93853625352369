import { memo } from 'react';
import { ProfileIconComponentType, ProfileType } from './types';
import Image from '@/components/Image';
import { Typography } from '@/components/Typography';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import LinkedinAvatarIcon from '@/static/assets/icons/AboutUs/LinkedinAvatarIcon.svg';
import TwitterAvatarIcon from '@/static/assets/icons/AboutUs/TwitterAvatarIcon.svg';
import WikipediaAvatarIcon from '@/static/assets/icons/AboutUs/WikipediaAvatarIcon.svg';
import './Profile.scss';

const ProfileIcon = ({ iconType }: ProfileIconComponentType) => {
  switch (iconType) {
    case 'wikipedia':
      return <WikipediaAvatarIcon />;
    case 'twitter':
      return <TwitterAvatarIcon />;
    default:
      return <LinkedinAvatarIcon />;
  }
};

const Profile = ({
  avatar,
  heading,
  description,
  profileIcon,
  redirectLink,
  alt
}: ProfileType) => {
  const { isBreakpointRange } = useBreakpoints();
  const isAboveSmallDesktop = !isBreakpointRange('desktop');

  return (
    <div className="profile-profile-container">
      <a
        className="profile-profile__image"
        href={redirectLink}
        target="_blank"
        rel="noreferrer"
      >
        <Image
          imageUrl={avatar}
          alt={alt}
          additionalClass={'profile-profile-image'}
        />
        <div className="profile-profile__icon">
          <ProfileIcon iconType={profileIcon} />
        </div>
      </a>
      <div className="profile-profile__heading">
        {isAboveSmallDesktop ? (
          <Typography
            component={'h3'}
            typographyKind={'header-secondary-small-bold'}
            size={24}
            lineHeight={32}
          >
            {heading}
          </Typography>
        ) : (
          <Typography
            component={'p'}
            typographyKind={'text-primary-large-bold'}
            size={18}
            lineHeight={24}
          >
            {heading}
          </Typography>
        )}
      </div>
      <div className="profile-profile__description">
        {isAboveSmallDesktop ? (
          <Typography
            component="p"
            typographyKind={'text-secondary-extra-large'}
            size={20}
            lineHeight={32}
          >
            {description}
          </Typography>
        ) : (
          <Typography
            component="p"
            typographyKind={'text-secondary-large-medium'}
            size={16}
            lineHeight={24}
          >
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default memo(Profile);
