import { en } from '@/languages/en';
import AlexProfileImage from '@/static/assets/images/AboutUs/about-us-founder--alex.png';
import HongProfileImage from '@/static/assets/images/AboutUs/about-us-founder--hong.png';
import JesseProfileImage from '@/static/assets/images/AboutUs/about-us-founder--jesse.png';
import AkuityFoundersImage from '@/static/assets/images/AboutUs/akuity-founders.jpg';
import GregSchott from '@/static/assets/images/Common/Persons/gregschott.jpeg';
import KelseyHightower from '@/static/assets/images/Common/Persons/kelseyhightower.jpeg';
import MallikMahalingam from '@/static/assets/images/Common/Persons/mallikmahalingam.jpeg';
import RamShriram from '@/static/assets/images/Common/Persons/ramshriram.jpeg';
import ShayBanon from '@/static/assets/images/Common/Persons/shaybanon.jpeg';
import StephenAugustus from '@/static/assets/images/Common/Persons/stephenaugustus.jpeg';
import { CONTACT_US_PAGE_URL } from '@/utils/urlUi';

const language = en;

export const aboutUsPage = {
  akuityStory: {
    title: language.aboutUs.header.title,
    description: language.aboutUs.header.description,
    image: {
      desktop: AkuityFoundersImage,
      mobile: AkuityFoundersImage,
      alt: 'Akuity founders'
    }
  },
  cta: {
    title: language.aboutUs.cta.title,
    subTitle: language.aboutUs.cta.subTitle,
    button: {
      text: language.aboutUs.cta.button,
      link: CONTACT_US_PAGE_URL,
      isExternal: false
    }
  },
  ourMission: {
    title: language.aboutUs.ourMission.title,
    description: language.aboutUs.ourMission.description
  },
  founders: {
    title: language.aboutUs.founders.title,
    items: [
      {
        id: 'about-us-founder-1',
        title: language.aboutUs.founders.firstFounder.title,
        description: language.aboutUs.founders.firstFounder.description,
        image: {
          url: HongProfileImage,
          alt: 'Founder Hong'
        }
      },
      {
        id: 'about-us-founder-2',
        title: language.aboutUs.founders.secondFounder.title,
        description: language.aboutUs.founders.secondFounder.description,
        image: {
          url: JesseProfileImage,
          alt: 'Founder Jess'
        }
      },
      {
        id: 'about-us-founder-3',
        title: language.aboutUs.founders.thirdFounder.title,
        description: language.aboutUs.founders.thirdFounder.description,
        image: {
          url: AlexProfileImage,
          alt: 'Founder Alex'
        }
      }
    ]
  },
  ourCommitment: {
    title: language.aboutUs.ourCommitment.title,
    image: {
      url: 'about-us-desktop-commitment.png',
      urlMobile: 'about-us-mobile-commitment.png',
      urlDesktopFullHD: 'about-us-desktop-commitment.png',
      alt: 'Akuity team'
    },
    akuity: {
      title: language.aboutUs.ourCommitment.akuity.title,
      description: language.aboutUs.ourCommitment.akuity.description,
      image: {
        name: 'home-akuity-logo.png',
        alt: 'akuity-logo'
      }
    },
    argo: {
      title: language.aboutUs.ourCommitment.argo.title,
      description: language.aboutUs.ourCommitment.argo.description,
      image: {
        name: 'home-argo-logo.png',
        alt: 'argo-logo'
      }
    }
  },
  investors: {
    title: language.aboutUs.investorsAndAdvisors.title,
    description: language.aboutUs.investorsAndAdvisors.description,
    items: [
      {
        avatar: StephenAugustus,
        heading:
          language.aboutUs.investorsAndAdvisors.persons.stephenAugustus.name,
        description:
          language.aboutUs.investorsAndAdvisors.persons.stephenAugustus
            .description,
        redirectLink: 'https://www.linkedin.com/in/stephenaugustus/',
        profileIcon: 'linkedin',
        alt: 'st-aug'
      },
      {
        avatar: ShayBanon,
        heading: language.aboutUs.investorsAndAdvisors.persons.shayBanon.name,
        description:
          language.aboutUs.investorsAndAdvisors.persons.shayBanon.description,
        redirectLink: 'https://www.linkedin.com/in/kimchy/',
        profileIcon: 'linkedin',
        alt: 'sy-banon'
      },
      {
        avatar: KelseyHightower,
        heading:
          language.aboutUs.investorsAndAdvisors.persons.kelseyHightower.name,
        description:
          language.aboutUs.investorsAndAdvisors.persons.kelseyHightower
            .description,
        redirectLink: 'https://twitter.com/kelseyhightower',
        profileIcon: 'twitter',
        alt: 'ky-hightower'
      },
      {
        avatar: MallikMahalingam,
        heading:
          language.aboutUs.investorsAndAdvisors.persons.malikMahalingam.name,
        description:
          language.aboutUs.investorsAndAdvisors.persons.malikMahalingam
            .description,
        redirectLink: 'https://www.linkedin.com/in/mallikmahalingam/',
        profileIcon: 'linkedin',
        alt: 'mk-mahalingam'
      },
      {
        avatar: RamShriram,
        heading: language.aboutUs.investorsAndAdvisors.persons.ramShriram.name,
        description:
          language.aboutUs.investorsAndAdvisors.persons.ramShriram.description,
        redirectLink: 'https://en.wikipedia.org/wiki/Ram_Shriram',
        profileIcon: 'wikipedia',
        alt: 'rm-shiriam'
      },
      {
        avatar: GregSchott,
        heading: language.aboutUs.investorsAndAdvisors.persons.gregSchott.name,
        description:
          language.aboutUs.investorsAndAdvisors.persons.gregSchott.description,
        redirectLink: 'https://www.linkedin.com/in/greg-schott-b27416/',
        profileIcon: 'linkedin',
        alt: 'gg-schott'
      }
    ]
  },
  ourMilestones: {
    title: language.aboutUs.milestones.title,
    items: [
      {
        title: language.aboutUs.milestones.milestonesOne.title,
        month: language.aboutUs.milestones.milestonesOne.month,
        year: language.aboutUs.milestones.milestonesOne.year
      },
      {
        title: language.aboutUs.milestones.milestonesTwo.title,
        month: language.aboutUs.milestones.milestonesTwo.month,
        year: language.aboutUs.milestones.milestonesTwo.year
      },
      {
        title: language.aboutUs.milestones.milestonesThree.title,
        month: language.aboutUs.milestones.milestonesThree.month,
        year: language.aboutUs.milestones.milestonesThree.year
      },
      {
        title: language.aboutUs.milestones.milestonesFour.title,
        month: language.aboutUs.milestones.milestonesFour.month,
        year: language.aboutUs.milestones.milestonesFour.year
      },
      {
        title: language.aboutUs.milestones.milestoneFive.title,
        month: language.aboutUs.milestones.milestoneFive.month,
        year: language.aboutUs.milestones.milestoneFive.year
      },
      {
        title: language.aboutUs.milestones.milestoneSix.title,
        month: language.aboutUs.milestones.milestoneSix.month,
        year: language.aboutUs.milestones.milestoneSix.year
      },
      {
        title: language.aboutUs.milestones.milestoneSeven.title,
        month: language.aboutUs.milestones.milestoneSeven.month,
        year: language.aboutUs.milestones.milestoneSeven.year
      },
      {
        title: language.aboutUs.milestones.milestoneEight.title,
        month: language.aboutUs.milestones.milestoneEight.month,
        year: language.aboutUs.milestones.milestoneEight.year
      },
      {
        title: language.aboutUs.milestones.milestoneNine.title,
        month: language.aboutUs.milestones.milestoneNine.month,
        year: language.aboutUs.milestones.milestoneNine.year
      },
      {
        title: language.aboutUs.milestones.milestoneTen.title,
        month: language.aboutUs.milestones.milestoneTen.month,
        year: language.aboutUs.milestones.milestoneTen.year
      },
      {
        title: language.aboutUs.milestones.milestoneEleven.title,
        month: language.aboutUs.milestones.milestoneEleven.month,
        year: language.aboutUs.milestones.milestoneEleven.year
      },
      {
        title: language.aboutUs.milestones.milestoneTwelve.title,
        month: language.aboutUs.milestones.milestoneTwelve.month,
        year: language.aboutUs.milestones.milestoneTwelve.year
      },
      {
        title: language.aboutUs.milestones.milestoneThirteen.title,
        month: language.aboutUs.milestones.milestoneThirteen.month,
        year: language.aboutUs.milestones.milestoneThirteen.year
      },
      language.aboutUs.milestones.milestoneForteen,
      language.aboutUs.milestones.milestoneFifteen,
      language.aboutUs.milestones.milestoneSixteen,
      language.aboutUs.milestones.milestoneSeventeen,
      language.aboutUs.milestones.milestoneEighteen
    ]
  }
};
